import React from "react"
import {navigate} from "gatsby"
import Layout from "../components/layout"

import Productlist from "../components/paintingtools/productlist"
import ControlSelect from "../components/controls/select"

import * as styles from "../styles/pages/productcategory.module.css"

//const ROOTCATEGORYURL="construction-chemicals"
const listcategory = require('../content/data/listcategory.json');
const listproduct = require('../content/data/listproduct.json');

const ProductcategoryTemplate = ({pageContext}) => {

	function onchangecategory(selected)
	{
		navigate("/"+selected);
	}

	function geturlvalue(tmpurl)
	{
		//return tmpurl === ROOTCATEGORYURL?"products":"products/category/"+tmpurl;
		return "products/category/"+tmpurl;
	}

	const cururl = geturlvalue(pageContext.url);
	var categorylist = [{
		"code": geturlvalue(""),
		"name": "All Products"
	}];
	var idx = 0;

	while (idx < listcategory.listcategoryobj.length) {
		if (parseInt(listcategory.listcategoryobj[idx].count,10) >= 1) {
			categorylist.push({
				"code": geturlvalue(listcategory.listcategoryobj[idx].url),
				"name": listcategory.listcategoryobj[idx].name
			})
		}
		idx++;
	}

	const pageDescription = "From water-based paints to solvent-based coatings, choose a BOYSEN product and find its product info, technical data, and more here."

	var finallist = [];
	var idx = 0;
	if (pageContext.id !== "0") {
		while (idx < listproduct.listproductobj.length) {
			if (listproduct.listproductobj[idx].catid === pageContext.id) {
				finallist.push(listproduct.listproductobj[idx]);
			}
			idx++;
		}
	} else {
		while (idx < listproduct.listproductobj.length) {
			// Don't include color collection
			if (listproduct.listproductobj[idx].catid !== "2") {
				finallist.push(listproduct.listproductobj[idx]);
			}
			idx++;
		}
	}

	return (
		<Layout activePath={cururl} pageTitle={pageContext.name} pageDescription={pageDescription}>
			<div className={styles.contentholder}>
				<div className={"text-centered textcolor-primary "+styles.title}>Our products</div>
				<div className="text-centered textcolor-primary">
					<div>From water-based paints to solvent-based coatings,</div>
					<div>choose a <span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span> product and find its product info, technical data, and more here.</div>
				</div>
				<div className={styles.selector+" text-centered"}>
					<ControlSelect list={categorylist} defaultvalue={cururl} handleChange={onchangecategory} />
				</div>
				<Productlist listproductobj={finallist} />
			</div>

		</Layout>
	)

}

export default ProductcategoryTemplate
